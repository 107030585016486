import { useTranslate } from "../../../../customHooks";
import { usePermissions } from '../../../../customHooks';
import { useCallback, useState } from "react";
import { toast } from "../../../../components";
import { FormLayout, Input, SubmitButton } from "@panwds/react-form";
import { isEmpty } from 'lodash';
import { useUpdateFirewallMutation } from "../../../../redux/services/firewalls-service";
import { extractFieldsWithConfig } from "../../../../utils/utils";
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Button, LoadingPanel } from "@panwds/react-ui";
import { FirewallEndpointsTable } from '../Edit';
import { EndpointsCreate } from "./EndpointComponents/EndpointsCreate";
import { EndpointsEdit } from "./EndpointComponents/EndpointsEdit";
import { validateAwsAccounts } from "../../../../utils/validate";
import { produce } from "immer";
import { firewallStyles } from "../../style";
import { FirewallUpdateBanner } from "../../FirewallUpdateBanner";

type PanelType = "" | "createEndpoint" | "editEndpoint";
type EndpointAction = "" | "createEndpoint" | "deleteEndpoint" | "editEndpoint" | "firewallUpdating";

const FirewallEndpoints = (props: { firewallData: Record<string, any>, isLoading: boolean, accounts: Record<string, any>[], refreshFirewallData: () => void }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const firewallUpdating = props?.firewallData?.Status?.FirewallStatus === "UPDATING" || '';
    const [sidePanel, setSidePanel] = useState<PanelType>("");
    const [editEndpointRecord, setEditEndpointRecord] = useState<object>({});
    const [endpointAction, setEndpointAction] = useState<EndpointAction>((firewallUpdating) ? "firewallUpdating" : "");
    const [updaFirewall] = useUpdateFirewallMutation();
    const config = {
        Firewall: ['FirewallId', 'EndpointServiceName', 'Region', 'EgressNAT', 'Endpoints', 'AllowListAccounts', 'CustomerZoneIdList', 'UpdateToken', 'DeploymentUpdateToken'],
    };
    const classes = firewallStyles();

    // Memoized function to transform the default form values
    const transformFormDefaultValue = useCallback((endpointData) => {
        if (isEmpty(endpointData)) {
            return undefined;
        }
        // Extract fields from firewall describe response based on the config.
        // Extracts fields needed for firewall endpoints form.
        const newStateWithConfig = extractFieldsWithConfig(endpointData, config);
        const { AllowListAccounts } = newStateWithConfig?.Firewall;
        return {
            Firewall: {
                ...newStateWithConfig.Firewall,
                Endpoints: newStateWithConfig.Firewall?.Endpoints ?? [],
                AllowListAccounts: Array.isArray(AllowListAccounts) ?
                    AllowListAccounts?.join(",") :
                    AllowListAccounts
            }
        }
    }, []);

    const transformFormSubmitValue = useCallback((formData: Record<string, any>) => {
        if (isEmpty(formData)) {
            return;
        }
        const transformedData = {
            ...formData.Firewall,
            AllowListAccounts: formData.Firewall.AllowListAccounts.split("," || []),
        }
        if (formData.Firewall.AllowListAccounts === "") delete transformedData.AllowListAccounts;
        delete transformedData.CustomerZoneIdList;
        return transformedData;
    }, []);

    const formMethods = useForm({defaultValues: transformFormDefaultValue(props.firewallData)});
    const {
        formState: { dirtyFields }, control, setValue, getValues, watch, reset,
    } = formMethods;

    const allowListedAwsAccounts = watch("Firewall.AllowListAccounts");
    const onSubmit = useCallback(
        async (data) => {
            try {
                delete data['EgressNAT'];
                const result = await updaFirewall({
                    payload: transformFormSubmitValue(data),
                }).unwrap();
                toast.success(translate(`resources.firewallsV2.endpointManagement.endpointUpdateSuccess`), { toastId: 'update-firewall-endpoints' });
                if (result) {
                    await props.refreshFirewallData();
                }
            }
            catch (error: any) {
                toast.error(`${error?.code}: ${error?.error}`, { toastId: "update-firewall-endpoints" });
                setEndpointAction("");
            }
        }, [permissions]
    );

    const createEndpoint = useCallback(
        async (data) => {
            setValue("Firewall.Endpoints", [...getValues("Firewall.Endpoints"), data], {shouldDirty: true});
            setSidePanel("");
            setEndpointAction("createEndpoint");
        }, [setValue, getValues]
    );

    const deleteEndpoint = useCallback(
        (row) => {
            const currentEndpoints = getValues("Firewall.Endpoints");
            setValue("Firewall.Endpoints", currentEndpoints.filter(
                (endpoint) => endpoint?.EndpointId !== row?.EndpointId || endpoint?.SubnetId !== row?.SubnetId
            ), { shouldDirty: true });
            if (row?.EndpointId) {
                setEndpointAction("deleteEndpoint");
            }
        }, [setValue, getValues]
    );

    const editEndpoint = useCallback(
        async (newEndpointData) => {
            const currentEndpoints = getValues("Firewall.Endpoints");
            const updatedState = produce(currentEndpoints, (draft) => {
                const index = draft.findIndex(item => item.EndpointId === newEndpointData?.EndpointId); // Find the index of the object to replace
                if (index !== -1) {
                    if (typeof newEndpointData?.Prefixes?.PrivatePrefix?.Cidrs === "string") {
                        newEndpointData.Prefixes.PrivatePrefix.Cidrs = newEndpointData.Prefixes.PrivatePrefix.Cidrs.split(",");
                    }
                  draft[index] = newEndpointData; // Replace the object at the found index with newObject
                }
            });
            setValue("Firewall.Endpoints", updatedState, {shouldDirty: true});
            setEndpointAction("editEndpoint");
            setSidePanel("");
        }, [setValue, getValues]
    );

    const setEditEndpointData = useCallback(
        async (row) => {
            setEditEndpointRecord(row);
            setSidePanel("editEndpoint");
        }, []
    );

    const handleCancel = () => {
        reset(transformFormDefaultValue(props.firewallData));
        setEndpointAction("");
    };

    return (
        <div className="tw-relative">
            {props?.isLoading &&
                <div className="tw-absolute tw-inset-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-backdrop-blur-sm tw-z-50" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    <div className="tw-w-6 tw-h-6">
                        <LoadingPanel />
                    </div>
                </div>
            }
            {firewallUpdating &&
            <FirewallUpdateBanner refresh={props.refreshFirewallData} />
            }
            <div className={classes.fwEditHeader}>{translate(`resources.firewallsV2.endpointManagement.title`)}</div>
            <div className={firewallUpdating ? classes.fwReadOnly : ""}>
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                        <FormLayout>
                            <Input
                                label={translate(`resources.firewallsV2.endpointManagement.endpointServiceName`)}
                                name="Firewall.EndpointServiceName"
                                dataMetrics="cloudngfw-firewall-edit-endpoint-service-name"
                                description={translate(`resources.firewallsV2.endpointManagement.firewallEndpointServiceNameEditDescription`)}
                                register={{ required: false }}
                                disabled
                            />
                            <Input
                                label={translate(`resources.firewallsV2.endpointManagement.allowListedAccounts`)}
                                name="Firewall.AllowListAccounts"
                                dataMetrics="cloudngfw-firewall-edit-allowlisted-aws-accounts"
                                register={{ required: false, validate: validateAwsAccounts }}
                                description={translate(`resources.firewallsV2.endpointManagement.firewallAllowlistedAwsAccountsEditDescription`)}
                            />
                            <Controller
                                name="Firewall.Endpoints"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <FirewallEndpointsTable key={endpointAction} endpointAction={endpointAction} allowListedAwsAccounts={allowListedAwsAccounts} setEditEndpointData={setEditEndpointData} deleteEndpoint={deleteEndpoint} setSidePanel={setSidePanel} {...field} label="Custom Field" />
                                )}
                            />

                            <div className="tw-flex tw-justify-end">
                                <Button addClassName="tw-mr-2" onClick={handleCancel} disabled={isEmpty(dirtyFields)}>
                                    {translate(`generic.cancel`)}
                                </Button>
                                <SubmitButton disabled={isEmpty(dirtyFields)}>
                                    {translate(`generic.save`)}
                                </SubmitButton>
                            </div>
                        </FormLayout>
                    </form>
                    {sidePanel === 'createEndpoint' && <EndpointsCreate
                        close={() => setSidePanel("")}
                        createEndpoint={createEndpoint}
                        allowlisteAwsAccounts={getValues('Firewall.AllowListAccounts')}
                        zones={getValues('Firewall.CustomerZoneIdList') || []}
                        accounts={props?.accounts || []}
                        region={props.firewallData?.Firewall?.Region}
                    />
                    }
                    {sidePanel === 'editEndpoint' && <EndpointsEdit
                        close={() => setSidePanel("")}
                        editEndpoint={editEndpoint}
                        editEndpointData={editEndpointRecord}
                        disableEgress={! props.firewallData?.Firewall?.EgressNAT?.Enabled}
                    />
                }
                </FormProvider>
            </div>
        </div>
    );
};export default FirewallEndpoints;
